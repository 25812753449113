import { Base } from './base';

export enum PlayerType {
  Bot,
  Human,
}

/**
 * A Player can represent a real human, or a bot.
 * Players can be assigned a team.
 * When a user joins a "Table", they become a "Player"
 */
export class Player extends Base {
  /**
   * The identifier of the player
   */
  id: string;
  /**
   * The name of the player
   */
  name: string;
  /**
   * Specifies what type of player
   */
  type: PlayerType = PlayerType.Human;

  constructor({
    id,
    name,
    type,
  }: {
    id: string;
    name?: string;
    type?: PlayerType;
  }) {
    super();
    this.id = id;
    this.name = name;
    this.type = type ?? PlayerType.Human;

    if (!this.name && this.type === PlayerType.Bot) {
      this.name = 'Bot';
    } 
  }
}
