import { SuperDomino } from '../index';
import { GameSide } from '../enums';
import { Domino } from '../types';

/**
 * Get the digit playable on the specified side
 */
export function getSideDomino(
  this: SuperDomino,
  side: GameSide,
): Domino | undefined {
  if (this[side].length) {
    return this[side][this[side].length - 1];
  } else {
    return this.root;
  }
}
