import { SuperDomino } from '../index';
import { assert, isNullOrUndefined } from '../../utils/index';
import { GameEventName, GameSide, SuperDominoMode } from '../enums';
import { SuperDominoGameConfiguration } from '../types';

/**
 * Start a new round of Domino
 */
export function start(
  this: SuperDomino,
  config: SuperDominoGameConfiguration = {
    extraPrize: 0,
    mode: SuperDominoMode.normal,
  },
  initialPlayerIndex?: number,
): void {
  // throw an error if the game
  // already started
  assert({
    assertion: this.inProgress(),
    message: 'Calling start() on already started SuperDomino game not allowed!',
  });

  this.config = config;

  if (isNullOrUndefined(this.config.extraPrize)) {
    this.config.extraPrize = 0;
  }

  if (isNullOrUndefined(this.config.mode)) {
    this.config.mode = SuperDominoMode.normal;
  }

  // set the game as started
  this.begin();
  this.newEvent({
    event: GameEventName.started,
  });

  // shuffle dominoes and distribute dominoes
  this.shuffleDominoes();

  // set the initial player
  if (isNullOrUndefined(initialPlayerIndex)) {
    // the first player is whoever has the double six
    this.currentPlayerIndex = this.playerWithDomino([6, 6]);
    this.play(this.sequence, this.currentPlayerIndex, {
      domino: [6, 6],
      side: GameSide.left,
    });
  } else {
    /**
     * if config.initialPlayerIndex has a value,
     * this means this is not the first game, and this index
     * is for the player who won the latest game
     */
    this.currentPlayerIndex = initialPlayerIndex;
  }

  // set the initial player
  this.initialPlayerIndex = this.currentPlayerIndex;
}
