import React, { useMemo } from 'react';
import { isNullOrUndefined, SuperDomino, Table } from '@littlegoose/common';
import DominoPiece from '../../../components/domino/domino';
import { GpsService } from '../services/gps-service';

/**
 * Hook for rendering domino pieces using the GPS service,
 * and CSS absolute positioning techniques.
 */
export const useDominoRenderer = ({
  height,
  table,
  width,
}: {
  height: number;
  table: Table | null;
  width: number;
}): React.ReactNode[] => {
  const game = table?.getGame() as SuperDomino;
  /**
   * The dominoes should be rendered again if the sequence changes,
   * or if the table size changes.
   */
  const sequence = game?.sequence ?? null;
  return useMemo(() => {
    if (isNullOrUndefined(sequence)) {
      return [];
    }

    const nodes: React.ReactNode[] = [];

    if (!game.root) {
      return nodes;
    }

    const gps = new GpsService(height, width);

    nodes.push(
      <DominoPiece
        {...gps.calculatePosition(game.root)}
        playedBy={table.whoPlayedDomino(game.root)}
      />,
    );

    game.left.forEach((domino) => {
      nodes.push(
        <DominoPiece
          {...gps.calculatePosition(domino)}
          playedBy={table.whoPlayedDomino(domino)}
        />,
      );
    });

    gps.startRightSide();

    game.right.forEach((domino) => {
      nodes.push(
        <DominoPiece
          {...gps.calculatePosition(domino, true)}
          playedBy={table.whoPlayedDomino(domino)}
        />,
      );
    });

    return nodes;
    // eslint-disable-next-line
  }, [sequence, height, width]);
};
