import { SuperDomino } from '../index';
import { GameSide } from '../enums';

/**
 * Get the digit playable on the specified side
 */
export function getSideDigit(
  this: SuperDomino,
  side: GameSide,
): number | undefined {
  const domino = this.getSideDomino(side);
  if (domino) {
    return domino[side === GameSide.left ? 0 : 1];
  }
}
