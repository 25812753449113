import { SuperDomino } from '../index';

/**
 * Returns the sum of all the Domino numbers for a given player
 */
export function getPlayerPointsInDeck(
  this: SuperDomino,
  playerIndex: number,
): number {
  return this.getPlayerDeck(playerIndex).reduce(
    (total, domino) => total + domino[0] + domino[1],
    0,
  );
}
