import { Base } from './base';

export type GameConfiguration = Record<string, unknown>;

export type PlayOptions = Record<string, unknown> | number | string;

/**
 * The different status a Game can be in
 */
export enum GameStatus {
  NotStarted,
  Started,
  Finished,
}

export interface GameEvent<Event = any, GameEventPayload = any> {
  /**
   * The id of the event
   */
  id?: number;

  /**
   * The name of the event
   */
  event: Event;

  /**
   * The payload of the  event
   */
  payload?: GameEventPayload & {
    player?: number;
  };

  /**
   * Time of event
   */
  timestamp?: number;
}

export abstract class Game<
  InheritedGameEvent extends GameEvent = undefined,
> extends Base {
  /**
   * The events the game emits
   */
  events: InheritedGameEvent[] = [];

  /**
   * Status of the Game
   */
  status: GameStatus = GameStatus.NotStarted;

  /**
   * The winner of the game. This is the index of the
   * player in the list of players
   */
  winnerIndex?: number;

  /**
   * The winning prize
   */
  prize: number = 0;

  /**
   * The date/time when the game started in Unix epoch
   */
  startedOn?: number;

  /**
   * The date/time when the game ended in Unix epoch
   */
  endedOn?: number;

  /**
   * The index of the current player
   */
  currentPlayerIndex: number = 0;

  /**
   * Current sequence of the game.
   * Everytime the game state changes, the sequence
   * needs to increase
   */
  sequence: number = 0;

  /**
   * Initialize the game.
   * Game is equivalent to a round.
   * This method should call begin()
   */
  abstract start(config?: GameConfiguration);

  /**
   * Current player to play. Value is optional to indicate
   * the current player is potentially a bot
   */
  abstract play(
    currentSequence: number,
    playerIndex: number,
    value?: PlayOptions,
  ): void;

  /**
   * Get status of the game
   */
  getStatus() {
    return this.status;
  }

  /**
   * Mark the game as started
   */
  begin() {
    this.status = GameStatus.Started;
    this.startedOn = Date.now();
  }

  /**
   * Returns true if the game is in-progress.
   */
  inProgress() {
    return this.getStatus() === GameStatus.Started;
  }

  /**
   * Mark the game as finished
   */
  finish() {
    this.status = GameStatus.Finished;
    this.endedOn = Date.now();
  }

  /**
   * @returns true if the game is finished
   */
  isFinished(): boolean {
    return this.status === GameStatus.Finished;
  }

  /**
   * Get the winner of the Game
   */
  getWinner(): number {
    return this.winnerIndex;
  }

  /**
   * Get the prize for the game
   */
  getPrize(): number {
    return this.prize;
  }

  /**
   * Get the index of the current player's turn
   */
  getCurrentPlayerIndex(): number {
    return this.currentPlayerIndex;
  }

  /**
   * Adds and returns an event
   */
  newEvent(event: InheritedGameEvent): InheritedGameEvent {
    // payload should default to an empty
    // object
    event.payload = event.payload || {};

    // the id of the event. this can be used
    // to process events in order
    event.id = this.sequence;
    event.timestamp = Date.now();
    this.sequence++;

    // add to list of events
    this.events.push(event);
    return event;
  }
}
