import * as tf from '@tensorflow/tfjs';
import { v4 } from 'uuid';
import { Table } from '../shared/table';
import { Player, PlayerType } from '../shared/player';
import { assert, isNullOrUndefined } from '../utils/index';
import { Domino } from '../game/types';
import {
  GameEventName,
  GameSide,
  SuperDominoWinningMethod,
  TableSeat,
} from '../game/enums';

export class AIBot extends Player {
  /**
   * Optional model to use for predictions
   */
  model?: tf.Sequential;
  /**
   * The player index
   */
  myPlayerIndex: number;

  constructor(model?: tf.Sequential) {
    super({
      id: v4(),
      type: PlayerType.Bot,
    });

    this.model = model;
  }

  joinTable(table: Table) {
    if (table.isHostPlayer(this.id)) {
      this.myPlayerIndex = 0;
    } else if (!table.playerB) {
      table.join({
        player: this,
        seat: TableSeat.B,
      });
      this.myPlayerIndex = 1;
    } else if (!table.playerC) {
      table.join({
        player: this,
        seat: TableSeat.C,
      });
      this.myPlayerIndex = 2;
    } else if (!table.playerD) {
      table.join({
        player: this,
        seat: TableSeat.D,
      });
      this.myPlayerIndex = 3;
    } else {
      throw new Error('Table is full');
    }
  }

  getMyDominoes(table: Table) {
    return table.getPlayerDeck(this.id);
  }

  getRandomPlayableDomino(dominoes: Domino[] = []): {
    domino: Domino;
    dominoIndex: number;
  } {
    const randomIndex = Math.floor(Math.random() * dominoes.length);
    return {
      domino: dominoes[randomIndex],
      dominoIndex: randomIndex,
    };
  }

  randomPlay(table: Table): {
    domino: Domino;
    dominoIndex: number;
  } {
    const playableDominoes = table.getCurrentPlayerPlayableDominoes();

    assert({
      assertion: playableDominoes.length === 0,
      message: 'No playable dominoes',
    });

    const resp = this.getRandomPlayableDomino(playableDominoes);

    assert({
      assertion: !resp.domino,
      message: 'No domino to play',
    });

    return resp;
  }

  play(
    table: Table,
    options: {
      // The epsilon value - between 0 and 1
      epsilon?: number;
      // The AI model
      model?: tf.Sequential;
      // Set to true to predict the next move
      // using the AI model
      predict?: boolean;
    } = {},
  ): { dominoIndex: number; gameState: number[]; rewards: number[] } {
    assert({
      assertion: table.getCurrentPlayer().id !== this.id,
      message: 'Not my turn',
    });

    const randomValue = Math.random();
    let action: {
      domino: Domino;
      dominoIndex: number;
    };

    const rewards: number[] = new Array(7).fill(-3);
    const myDeck = this.getMyDominoes(table);

    for (const [i, d] of myDeck.entries()) {
      const playable =
        table.canPutDomino(d, GameSide.left) ||
        table.canPutDomino(d, GameSide.right);

      if (playable) {
        rewards[i] = 0;
      }
    }

    const gameState = this.encodeGameState(table);

    const model = options.model || this.model;

    if (randomValue < options?.epsilon || !options.predict || !model) {
      action = this.randomPlay(table);
    } else {
      action = this.predictPlay(table, gameState, model);
    }

    const side = table.canPutDomino(action.domino, GameSide.left)
      ? GameSide.left
      : GameSide.right;

    const sequence = table.getSequence();

    assert({
      assertion: isNullOrUndefined(sequence) || sequence < 0,
      message: 'Invalid sequence',
    });
    table.play(this.id, sequence, { domino: action.domino, side });

    const game = table.getGame();
    const myTeammate = (this.myPlayerIndex + 2) % 4;
    const dominoIndex = action.dominoIndex;

    if (game.isFinished()) {
      const wasKapi = game.events.some(
        (event) =>
          event.event === GameEventName.finished &&
          event.payload.mode === SuperDominoWinningMethod.kapi,
      );
      if (
        game.winnerIndex === this.myPlayerIndex ||
        game.winnerIndex === myTeammate
      ) {
        rewards[dominoIndex] = wasKapi ? 2 : 1;
      } else {
        rewards[dominoIndex] = wasKapi ? -2 : -1;
      }
    } else {
      const numberOfPasses = game.events.filter(
        (event) => event.event === GameEventName.pass && event.id > sequence,
      ).length;

      if (numberOfPasses === 1) {
        /**
         * Initial value: 0.2
         */
        rewards[dominoIndex] += 0.5;
      }

      if (numberOfPasses === 2) {
        rewards[dominoIndex] -= 0.5;
      }

      if (numberOfPasses === 3) {
        /**
         * Initial value: 0.4
         */
        rewards[dominoIndex] += 0.5;
      }

      const numberOfDoubleKills = game.events.filter(
        (event) =>
          event.event === GameEventName.double_killed && event.id > sequence,
      ).length;

      if (numberOfDoubleKills === 1) {
        /**
         * Initial value: 0.1
         */
        rewards[dominoIndex] += 0.1;
      }

      if (numberOfDoubleKills === 2) {
        /**
         * Initial value: 0.2
         */
        rewards[dominoIndex] += 0.2;
      }
    }

    return {
      dominoIndex: action.dominoIndex,
      gameState,
      rewards,
    };
  }

  encodeGameState(table: Table): number[] {
    const MAX_DOMINOES_PER_PLAYER = 7;
    const MAX_DOMINOES = 28;
    const MAX_DOMINOES_PER_SIDE = MAX_DOMINOES - 1;

    const gameState: number[] = [];

    const playerToTheRight = (this.myPlayerIndex + 1) % 4;
    const myTeammate = (this.myPlayerIndex + 2) % 4;
    const playerToTheLeft = (this.myPlayerIndex + 3) % 4;

    const addDomino = (domino: Domino | undefined) => {
      if (domino) {
        gameState.push(domino[0]);
        gameState.push(domino[1]);
      } else {
        gameState.push(-1);
        gameState.push(-1);
      }
    };

    const game = table.getGame();

    const findWhoPlayedDomino = (domino: Domino): number => {
      const event = game.events.find(
        (event) =>
          event.event === GameEventName.played &&
          ((event.payload.domino[0] === domino[0] &&
            event.payload.domino[1] === domino[1]) ||
            (event.payload.domino[1] === domino[0] &&
              event.payload.domino[0] === domino[1])),
      );

      if (isNullOrUndefined(event?.payload?.player)) {
        throw new Error('Player not found');
      }

      switch (event.payload.player) {
        case playerToTheRight:
          return 1;
        case myTeammate:
          return 2;
        case playerToTheLeft:
          return 3;
        default:
          return 0;
      }
    };

    // Encode the dominoes in the AI's hand
    const myDeck = this.getMyDominoes(table);

    // Encode AI's dominoes
    myDeck.forEach((domino) => {
      addDomino(domino);

      // encode if the domino is playable
      const playable =
        game.canPutDomino(domino, GameSide.left) ||
        game.canPutDomino(domino, GameSide.right);
      gameState.push(playable ? 1 : 0);
    });

    const paddingNeededForMissingDominoes =
      MAX_DOMINOES_PER_PLAYER - myDeck.length;

    // Pad if less than max domino count
    // multiplied by 3 (2 for domino values
    // and 1 for playable flag)
    for (let i = 0; i < paddingNeededForMissingDominoes; i++) {
      gameState.push(-1, -1, -1);
    }

    // Encode number of dominoes each player has
    // with 1 indicating the AI's teammate and 0 indicating the opponents
    gameState.push(0);
    gameState.push(1); // 1 indicates the AI, or it
    gameState.push(myDeck.length);

    gameState.push(1);
    gameState.push(0);
    gameState.push(table.getPlayerDeckByIndex(playerToTheRight).length);

    gameState.push(2);
    gameState.push(1);
    gameState.push(table.getPlayerDeckByIndex(myTeammate).length);

    gameState.push(3);
    gameState.push(0);
    gameState.push(table.getPlayerDeckByIndex(playerToTheLeft).length);

    // encode the played dominoes for the left side
    // multiplied by 3 (2 for domino values and 1 for the player who played it)
    const paddingNeededForLeftSide = MAX_DOMINOES_PER_SIDE - game.left.length;

    // Padding value for non-existent dominoes
    for (let i = 0; i < paddingNeededForLeftSide; i++) {
      gameState.push(-1, -1, -1);
    }

    // Encode the played dominoes on the left
    game.left.forEach((domino) => {
      addDomino(domino);
      gameState.push(findWhoPlayedDomino(domino));
    });

    // encode the first domino played
    if (game.root) {
      addDomino(game.root);
      gameState.push(findWhoPlayedDomino(game.root));
    } else {
      gameState.push(-1, -1, -1);
    }

    // encode the played dominoes for the right side
    game.right.forEach((domino) => {
      addDomino(domino);
      gameState.push(findWhoPlayedDomino(domino));
    });

    // Padding value for non-existent dominoes
    const paddingNeededForRightSide = MAX_DOMINOES_PER_SIDE - game.right.length;
    for (let i = 0; i < paddingNeededForRightSide; i++) {
      gameState.push(-1, -1, -1);
    }

    // encode the digits on the left and right sides
    const [left, right] = game.getSides();

    // use -1 to indicate no dominoes played
    if (isNullOrUndefined(left)) {
      gameState.push(-1);
    } else {
      gameState.push(left);
    }

    // use -1 to indicate no dominoes played
    if (isNullOrUndefined(right)) {
      gameState.push(-1);
    } else {
      gameState.push(right);
    }

    // encode dominoes not played yet
    game.unplayed.forEach((domino) => {
      addDomino(domino);
    });

    // add padding for the unplayed dominoes
    const paddingNeededForUnplayedDominoes =
      MAX_DOMINOES - game.unplayed.length;
    for (let i = 0; i < paddingNeededForUnplayedDominoes; i++) {
      gameState.push(-1, -1);
    }

    return gameState;
  }

  predictPlay(
    table: Table,
    gameState: number[],
    model: tf.Sequential,
  ): {
    domino: Domino;
    dominoIndex: number;
  } {
    const myDeck = this.getMyDominoes(table);
    const predictedValues = this.modelPredict(gameState, model);

    let domino: Domino;
    let dominoIndex: number;
    let maxScore = -Infinity;

    for (const [i, d] of myDeck.entries()) {
      const playable =
        table.canPutDomino(d, GameSide.left) ||
        table.canPutDomino(d, GameSide.right);

      if (playable) {
        const score = predictedValues[i];
        if (score > maxScore) {
          maxScore = score;
          domino = d;
          dominoIndex = i;
        }
      }
    }

    assert({
      assertion: !domino,
      message: 'No domino to play',
    });

    return {
      domino,
      dominoIndex,
    };
  }

  modelPredict(gameState: number[], model: tf.Sequential) {
    // Convert gameState to a tensor (assuming gameState is already a normalized array)
    const inputTensor = tf.tensor2d([gameState]); // Wrap gameState in an array to match batch dimension expected by TF.js

    // Make predictions using the model
    const predictions = model.predict(inputTensor) as tf.Tensor<tf.Rank>;

    // Convert the predictions tensor to a regular array for easier handling in JavaScript
    const predictedValues = predictions.dataSync(); // Use dataSync() to get the values from the tensor

    // Dispose of tensors to free up GPU memory
    inputTensor.dispose();
    predictions.dispose();

    // Return the array of predicted values
    return predictedValues;
  }
}
