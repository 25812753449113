import React, { useContext } from 'react';
import { useContainerSize } from './hooks/use-container-size';
import { useDominoRenderer } from './hooks/use-domino-renderer';

import './table.css';
import { GameContext } from '../context/game-context';

const Table: React.FC<{}> = () => {
  const { table } = useContext(GameContext);
  const { ref, size } = useContainerSize();
  const dominoes = useDominoRenderer({
    height: size.height,
    table,
    width: size.width,
  });

  return (
    <div className="board">
      <div className="table" ref={ref}>
        {dominoes}
      </div>
    </div>
  );
};

export default Table;
