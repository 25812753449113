import React from 'react';

import './card.css';

const Card: React.FC<
  {
    onClick?: () => void;
    selected?: boolean;
  } & React.PropsWithChildren
> = ({ children, onClick, selected }) => {
  let className = 'card';

  if (selected) {
    className += ' selected';
  }

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};

export default Card;
