import React from 'react';
import { useRouteError } from 'react-router-dom';

const ErrorPage: React.FC = () => {
  const error = useRouteError() as
    | { message?: string; statusText?: string }
    | undefined;
  console.error(error);

  return (
    <div className="error-page boogaloo-regular">
      <img className="logo" src="/sad_domino.webp" alt="logo" />
      <br />
      <br />
      <br />
      <div className="playful-font font-xxlarge">Oops!</div>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>{error?.statusText || error.message}</p>
    </div>
  );
};

export default ErrorPage;
