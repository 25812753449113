import { SuperDomino } from '../index';
import { isDouble } from '../utils';

/**
 * Checks if any of the players have 5 or more
 * doubles in their decks
 */
export function anyPlayerHasFiveOrMoreDoubles(this: SuperDomino): boolean {
  for (const deck of this.decks) {
    const numOfDoubles = deck.reduce(
      (prev, curr) => (isDouble(curr) ? prev + 1 : prev),
      0,
    );

    if (numOfDoubles > 4) {
      return true;
    }
  }

  return false;
}
