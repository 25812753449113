/**
 * All classes should inherit from this Class.
 * This base class brings functionality to easily initialize
 * classes, along with custom methods executed upon initialization
 */
export class Base {
  /**
   * Create a Deep Clone Instance of this Class
   */
  static clone<T extends Base>(this: new () => T, data: T): T {
    const p = Object.assign(new this(), JSON.parse(JSON.stringify(data)));
    p.afterLoad();
    return p;
  }

  /**
   * Create class from data. This will execute the afterLoad() method
   * if defined on the class.
   */
  static create<T extends Base>(this: new () => T, data: Partial<T>): T {
    const p = Object.assign(new this(), data);
    p.afterLoad();
    return p;
  }

  /**
   * Initialize class from data. This will execute the afterLoad() method
   * if defined on the class.
   */
  static fromData<T extends Base>(this: new () => T, data: Partial<T>): T {
    const p = Object.assign(new this(), data);
    p.afterLoad();
    return p;
  }

  /**
   * Declare this function on Classes that extend this Class
   * if you need to perform actions on the class when creating
   * the class using the "fromData" static method.
   */
  afterLoad() {} // eslint-disable-line
}
