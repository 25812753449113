import { TeamId } from '../game/enums';
import { Base } from './base';

export class Team extends Base {
  /**
   * ID for the Team
   */
  id: TeamId;

  /**
   * Friendly name for the Team
   */
  name: string;

  /**
   * Current score for the team
   */
  score: number = 0;

  /**
   * Increase the score of the team
   */
  addScore(score: number = 0) {
    this.score += score;
  }

  constructor({ id, name }: { id: TeamId; name: string }) {
    super();
    this.id = id;
    this.name = name;
  }
}
