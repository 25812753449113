import {
  AIBot,
  Domino,
  GameSide,
  PlayMode,
  Player,
  SuperDominoMode,
  Table,
  timeout,
} from '@littlegoose/common';
import { PlayerType } from '@littlegoose/common/src/shared/player';
import { Sequential } from '@tensorflow/tfjs';
import { IFGameContext } from 'src/room/context/types';
import { User } from 'src/types';

class GameManager {
  async startLocalGame({
    context,
    mode,
    model,
    user,
  }: {
    context: IFGameContext;
    mode: SuperDominoMode;
    model: Sequential;
    user: User;
  }) {
    const player = new Player({ id: user.id, name: user.name });
    const botB = new AIBot(model as any);
    botB.name = 'R2D2 - Bot';
    const botC = new AIBot(model as any);
    botC.name = 'C3PO - Bot';
    const botD = new AIBot(model as any);
    botD.name = 'BB8 - Bot';

    const table = new Table(
      {
        mode,
        playMode: PlayMode.local,
      },
      player,
    );

    botB.joinTable(table);
    botC.joinTable(table);
    botD.joinTable(table);

    table.newRound();

    context.updateContext({
      playerId: user.id,
      sequence: table.getSequence(),
      table,
    });

    await this.playBotTurn(table, context);
  }

  async playBotTurn(table: Table, context: IFGameContext) {
    while (table.gameInProgress()) {
      const currentPlayer = table.getCurrentPlayer() as AIBot;
      if (currentPlayer.type === PlayerType.Human) {
        break;
      }

      await timeout(2500);

      if (table.getCurrentPlayer().id !== currentPlayer.id) {
        break;
      }
      
      currentPlayer.play(table, {
        epsilon: 0.1,
        predict: true,
      });

      context.updateContext({
        sequence: table.getSequence(),
        table,
      });
    }
  }

  async playHumanTurn({
    context,
    domino,
    side,
  }: {
    context: IFGameContext;
    domino: Domino;
    side: GameSide;
  }) {
    const { table } = context;
    const currentPlayer = table.getCurrentPlayer();
    if (currentPlayer.type === PlayerType.Bot) {
      return;
    }

    if (currentPlayer.id !== context.playerId) {
      return;
    }

    table.play(context.playerId, table.getSequence(), {
      domino,
      side,
    });

    context.updateContext({
      sequence: table.getSequence(),
      table,
    });

    await this.playBotTurn(table, context);
  }

  async newRound({ context, user }: { context: IFGameContext; user: User }) {
    const { table } = context;
    table.newRound();

    context.updateContext({
      playerId: user.id,
      sequence: table.getSequence(),
      table,
    });

    await this.playBotTurn(table, context);
  }

  async rematch({ context, user }: { context: IFGameContext; user: User }) {
    const { table } = context;
    table.resetTable();
    table.newRound();

    context.updateContext({
      playerId: user.id,
      sequence: table.getSequence(),
      table,
    });

    await this.playBotTurn(table, context);
  }
}

const gameManager = new GameManager();
export default gameManager;
