import React from 'react';

import './play-selection.css';
import DominoPiece from 'src/components/domino/domino';
import { Domino, GameSide } from '@littlegoose/common';

const PlaySelection: React.FC<{
  domino: Domino;
  leftSide: Domino;
  rightSide: Domino;
  onClose: () => void;
  onSelection: (domino: Domino, side: GameSide) => void;
}> = ({ domino, leftSide, rightSide, onClose, onSelection }) => {
  if (!domino) {
    return null;
  }

  return (
    <div className="play-selection-overlay" onClick={onClose}>
      <div
        className="play-selection-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="title">
          <div className="playful-font">Which side to play?</div>
          <br />
          <DominoPiece canPlay domino={domino} horizontal onClick={onClose} />
        </div>
        <div className="row">
          <div className="option">
            <DominoPiece
              canPlay
              domino={leftSide}
              onClick={() => onSelection(domino, GameSide.left)}
            />
          </div>
          <div className="option">
            <DominoPiece
              canPlay
              domino={rightSide}
              onClick={() => onSelection(domino, GameSide.right)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaySelection;
