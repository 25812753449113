import { SuperDomino } from '../index';
import { Domino } from '../types';
import { areDominoesEqual } from '../utils';

/**
 * Check if a player has a given domino
 */
export function playerHasDomino(
  this: SuperDomino,
  playerIdx: number,
  domino: Domino,
): boolean {
  return !!this.getPlayerDeck(playerIdx).find((d) =>
    areDominoesEqual(d, domino),
  );
}
