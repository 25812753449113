import React, { useContext, useEffect } from 'react';
import TableComponent from './table/table';
import Top from './top';
import Deck from './deck/deck';
import StartMenu from './start';
import { GameContext, useGameContext } from './context/game-context';
import { useUser } from 'src/hooks/use-user';

import './room.css';
import ResultModal from './result/result';
import { useParams } from 'react-router-dom';
import gameManager from 'src/services/game-manager';

const InnerRoom: React.FC<{}> = () => {
  const context = useContext(GameContext);
  const { table } = context;

  /**
   * If the game is restarting, play the bot's turn
   */
  useEffect(() => {
    if (table) {
      gameManager.playBotTurn(table, context)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!table) {
    return <div>Loading...</div>;
  }

  return (
    <div className="room">
      <Top />
      <TableComponent />
      <Deck />
      <ResultModal />
    </div>
  );
};

const Room: React.FC<{}> = () => {
  const { tableId } = useParams();
  const context = useGameContext(tableId);
  const [user] = useUser();

  if (!user || !context) {
    return <div>Loading...</div>;
  }

  return (
    <GameContext.Provider value={context}>
      {!context.table ? <StartMenu /> : <InnerRoom />}
    </GameContext.Provider>
  );
};

export default Room;
