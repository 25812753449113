export enum SuperDominoMode {
  normal = 'normal',
  super = 'super',
}

export enum SuperDominoWinningMethod {
  chuzazo = 'chuzazo',
  closed = 'closed',
  dominao = 'dominao',
  kapi = 'kapi',
  normal = 'normal',
}

export enum TeamId {
  A = 'a',
  B = 'b',
}

export enum TableSeat {
  B = 'b',
  C = 'c',
  D = 'd',
}

export enum GameSide {
  left = 'left',
  right = 'right',
}

export enum GameEventName {
  double_killed = 'double_killed',
  error = 'error',
  finished = 'finished',
  pass = 'pass',
  played = 'played',
  shuffle = 'shuffle',
  started = 'started',
}

export enum PlayMode {
  local = 'local',
  multiplayer_private = 'multiplayer_private',
  multiplayer_public = 'multiplayer_public',
  tournament = 'tournament',
}
