import { SuperDomino } from '../index';
import { isNullOrUndefined } from '../../utils/index';

/**
 * Given a player index, this method will
 * return the index of the next player
 */
export function getNextPlayerIndex(
  this: SuperDomino,
  playerIdx?: number,
): number {
  if (isNullOrUndefined(playerIdx)) {
    playerIdx = this.currentPlayerIndex;
  }

  return playerIdx === 3 ? 0 : playerIdx + 1;
}
