import { SuperDomino } from '../index';
import { GameSide } from '../enums';
import { Domino } from '../types';

/**
 * Returns a tuple representing the dominoes on each side
 * of the Domino Table
 */
export function getSideDominoes(this: SuperDomino): [Domino, Domino] {
  return [
    this.getSideDomino(GameSide.left),
    this.getSideDomino(GameSide.right),
  ];
}
