import React from 'react';
import './main-menu.css';
import Button from '../components/button/button';
import { useNavigate } from 'react-router-dom';

const MainMenu: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="main-menu section">
      <div className="container">
        <img className="logo" src="/logo.webp" alt="logo" />
        <br />
        <br />
        <Button
          onClick={() => {
            navigate('/play/local');
          }}
        >
          Practice
        </Button>
      </div>
    </div>
  );
};

export default MainMenu;
