import { SuperDomino } from '../index';

/**
 * Calculates the sum of dominoes for each player. This method will
 * return the index of the player that has the least amount of points.
 */
export function getPlayerWithLeastPointsInDeck(this: SuperDomino): number {
  let theLowest = this.currentPlayerIndex;
  let count: number = this.getPlayerPointsInDeck(theLowest);
  let nextPlayer = this.getNextPlayerIndex(theLowest);

  while (nextPlayer !== this.currentPlayerIndex) {
    const nextCount = this.getPlayerPointsInDeck(nextPlayer);
    if (nextCount < count) {
      theLowest = nextPlayer;
      count = nextCount;
    } else if (nextCount === count) {
      const theHandTeam = this.getPlayerTeam(this.initialPlayerIndex);
      const thePrevTeam = this.getPlayerTeam(theLowest);
      const theNextTeam = this.getPlayerTeam(nextPlayer);
      if (theNextTeam !== thePrevTeam) {
        if (theNextTeam === theHandTeam) {
          theLowest = nextPlayer;
        }
      } else {
        if (
          this.initialPlayerIndex === theLowest ||
          this.initialPlayerIndex === nextPlayer
        ) {
          theLowest = this.initialPlayerIndex;
        } else {
          theLowest = nextPlayer;
        }
      }
    }

    nextPlayer = this.getNextPlayerIndex(nextPlayer);
  }

  return theLowest;
}
