import React, { useContext } from 'react';

import PlayerStats from './player-stats';
import Menu from './menu';

import './top.css';
import { GameContext } from './context/game-context';

const Top: React.FC<{}> = () => {
  const { playerId, table } = useContext(GameContext);
  const { us, them } = table.getTeamScores(playerId);
  const { left, front, right } = table.getOtherPlayersStats(playerId);
  const currentPlayer = table.getCurrentPlayer();

  return (
    <div className="top">
      <div className="column team-stats">
        <div className="playful-font">Us: {us}</div>
        <div className="playful-font">Them: {them}</div>
      </div>
      <div className="column row flex-2">
        <PlayerStats
          canPlay={currentPlayer?.id === left.player?.id}
          didPass={table.didPlayerPass(left.player?.id)}
          dominoCount={left.dominoCount}
          isHand={table.isTheHand(left.player?.id)}
          player={left.player}
          teamLabel="Left Opponent"
        />
        <PlayerStats
          canPlay={currentPlayer?.id === front.player?.id}
          didPass={table.didPlayerPass(front.player?.id)}
          dominoCount={front.dominoCount}
          isHand={table.isTheHand(front.player?.id)}
          player={front.player}
          teamLabel="Teammate"
        />
        <PlayerStats
          canPlay={currentPlayer?.id === right.player?.id}
          didPass={table.didPlayerPass(right.player?.id)}
          dominoCount={right.dominoCount}
          isHand={table.isTheHand(right.player?.id)}
          player={right.player}
          teamLabel="Right Opponent"
        />
      </div>
      <div className="column" style={{ paddingRight: 0 }}>
        <Menu />
      </div>
    </div>
  );
};

export default Top;
