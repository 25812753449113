import { SuperDomino } from '../index';
import { SuperDominoTeams } from '../types';

/**
 * Returns the team on which the player belongs to
 */
export function getPlayerTeam(
  this: SuperDomino,
  playerIndex: number,
): SuperDominoTeams {
  switch (playerIndex) {
    case 0:
    case 2:
      return SuperDominoTeams.TeamA;
    default:
      return SuperDominoTeams.TeamB;
  }
}
