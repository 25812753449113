import React from 'react';
import CloseIcon from 'src/icons/close.svg';

import './modal.css';

const Modal: React.FC<
  {
    centerContents?: boolean;
    hideCloseButton?: boolean;
    isOpen: boolean;
    onClose: () => void;
    title: string;
  } & React.PropsWithChildren
> = ({ centerContents, children, hideCloseButton, isOpen, onClose, title }) => {
  if (!isOpen) {
    return null;
  }

  let contentClassName = 'modal-content';

  if (centerContents) {
    contentClassName += ' center';
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className={contentClassName} onClick={(e) => e.stopPropagation()}>
        <div className="modal-close-row">
          <div className="modal-title playful-font">{title}</div>
          {!hideCloseButton && (
            <div
              className="modal-close-button"
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            >
              <CloseIcon />
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Modal;
