import React, { useCallback, useContext } from 'react';
import Button from 'src/components/button/button';
import Modal from 'src/components/modal/modal';
import Card from 'src/components/card/card';
import { SuperDominoMode } from '@littlegoose/common';
import gameManager from 'src/services/game-manager';
import { GameContext } from './context/game-context';
import { useBotModel } from 'src/hooks/use-bot-model';
import { useUser } from 'src/hooks/use-user';

const StartMenu: React.FC<{}> = () => {
  const model = useBotModel();
  const context = useContext(GameContext);
  const [user] = useUser();
  const [mode, setMode] = React.useState<SuperDominoMode>(
    SuperDominoMode.super,
  );

  const startGame = useCallback(() => {
    if (!context || !model || !user) {
      return;
    }
    (async () => {
      await gameManager.startLocalGame({ context, mode, model, user });
    })();
  }, [mode, model, context, user]);

  return (
    <Modal
      hideCloseButton
      isOpen={true}
      onClose={() => {}}
      title="SuperDomino"
    >
      <p>
        <strong>SuperDomino</strong> is a game of dominoes Puerto Rican style
        with a twist. Choose what mode you want to play and start the game.
      </p>
      <Card
        onClick={() => setMode(SuperDominoMode.super)}
        selected={mode === SuperDominoMode.super}
      >
        <h2 className="playful-font">Super (Quick Play)</h2>
        <p>
          Dominoes with a twist. First team to win 5 rounds wins! Kapi and
          Chuzazo count as two rounds!
        </p>
      </Card>
      <Card
        onClick={() => setMode(SuperDominoMode.normal)}
        selected={mode === SuperDominoMode.normal}
      >
        <h2 className="playful-font">Traditional</h2>
        <p>
          Play a traditional game of dominoes. First team to reach 500 points
          wins!
        </p>
      </Card>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button onClick={startGame}>Start</Button>
      </div>
    </Modal>
  );
};

export default StartMenu;
