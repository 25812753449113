import { SuperDomino } from '../index';
import { DominoDeck } from '../types';

/**
 * Given a player index, return the
 * domino deck
 */
export function getPlayerDeck(
  this: SuperDomino,
  playerIdx: number,
): DominoDeck {
  return this.decks[playerIdx];
}
