import { Player, isNullOrUndefined } from '@littlegoose/common';
import React from 'react';

import './player-stats.css';

const PlayerStats: React.FC<{
  canPlay?: boolean;
  didPass?: boolean;
  dominoCount?: number;
  isHand?: boolean;
  teamLabel?: string;
  player?: Player;
}> = ({ canPlay, didPass, dominoCount, isHand, player, teamLabel }) => {
  let subtitle = <div className="team boogaloo-regular">{teamLabel}</div>;

  if (canPlay) {
    subtitle = <div className="current-turn boogaloo-regular">Turn</div>;
  } else if (didPass) {
    subtitle = <div className="passed boogaloo-regular">Passed</div>;
  }

  return (
    <div className="player-stats">
      <div className="count playful-font">
        {isNullOrUndefined(dominoCount) ? '-' : dominoCount}
      </div>
      <div className="details">
        <div className="name playful-font">{player?.name || 'Player'}</div>
        <div className="detail-subtitle">
          {isHand && <div className="hand">👋</div>}
          {subtitle}
        </div>
      </div>
    </div>
  );
};

export default PlayerStats;
