import { Domino, isDouble } from "@littlegoose/common";

const MOBILE_BREAKPOINT = 700;

enum Direction {
    up,
    down,
    left,
    right,
  }
  
  interface DominoPosition {
    domino: Domino;
    key: string;
    horizontal: boolean;
    position: {
      x: number;
      y: number;
    };
    small: boolean;
  }
  
export class GpsService {
    dimensions: {
      length: number;
      width: number;
    };
    direction: Direction;
    initialDirection: Direction;
    previousDirection: Direction | undefined;
    initialHorizontal: boolean;
    initialX: number;
    initialY: number;
    isSmall: boolean;
    height: number;
    rootPosition: DominoPosition;
    lastPosition: DominoPosition;
    width: number;
    x: number;
    y: number;
  
    constructor(height: number, width: number) {
      this.initialX = width / 2;
      this.initialY = height / 2;
      this.x = this.initialX;
      this.y = this.initialY;
      this.height = height;
      this.width = width;
      this.isSmall = width <= MOBILE_BREAKPOINT;
  
      this.direction = this.isSmall ? Direction.up : Direction.left;
      this.initialDirection = this.direction;
  
      this.dimensions = this.isSmall
        ? {
            length: 57,
            width: 33,
          }
        : {
            length: 101,
            width: 57,
          };
    }
  
    getHalfWidth(): number {
      return this.dimensions.width / 2;
    }
  
    getOneThirdWidth(): number {
      return this.dimensions.width / 3;
    }
  
    getHalfLength(): number {
      return this.dimensions.length / 2;
    }
  
    getOneThirdLength(): number {
      return this.dimensions.length / 3;
    }
  
    calculatePosition(domino: Domino, rightSide?: boolean): DominoPosition {
      const isADouble = isDouble(domino);
  
      const directionChanged = this.determineDirection();
  
      let horizontal =
        this.direction === Direction.up || this.direction === Direction.down
          ? isADouble
          : !isADouble;
  
      if (directionChanged && isADouble) {
        horizontal = !horizontal;
      }
  
      if (this.lastPosition) {
        const lastOneWasADouble = isDouble(this.lastPosition.domino);
        switch (this.direction) {
          case Direction.up:
            if (this.lastPosition.horizontal) {
              this.y -= this.getHalfWidth();
            } else {
              this.y -= this.getHalfLength();
            }
            if (directionChanged && !lastOneWasADouble) {
              this.x += this.getOneThirdWidth();
            }
            this.y -= horizontal ? this.getHalfWidth() : this.getHalfLength();
            break;
          case Direction.down:
            if (this.lastPosition.horizontal) {
              this.y += this.getHalfWidth();
            } else {
              this.y += this.getHalfLength();
            }
            if (directionChanged && !lastOneWasADouble) {
              this.x -= this.getOneThirdWidth();
            }
            this.y += horizontal ? this.getHalfWidth() : this.getHalfLength();
            break;
          case Direction.left:
            if (this.lastPosition.horizontal) {
              this.x -= this.getHalfLength();
            } else {
              this.x -= this.getHalfWidth();
            }
            if (directionChanged && !lastOneWasADouble) {
              this.y -= this.getOneThirdWidth();
            }
            this.x -= horizontal ? this.getHalfLength() : this.getHalfWidth();
            break;
          case Direction.right:
            if (this.lastPosition.horizontal) {
              this.x += this.getHalfLength();
            } else {
              this.x += this.getHalfWidth();
            }
            if (directionChanged && !lastOneWasADouble) {
              this.y += this.getOneThirdWidth();
            }
            this.x += horizontal ? this.getHalfLength() : this.getHalfWidth();
            break;
        }
      }
  
      const dominoPiece: Domino = [domino[0], domino[1]];
  
      if (
        ((this.previousDirection === Direction.left &&
          this.direction === Direction.down) ||
          (this.previousDirection === Direction.down &&
            this.direction === Direction.right)) &&
        !rightSide
      ) {
        dominoPiece.reverse();
      }
  
      if (
        ((this.previousDirection === Direction.right &&
          this.direction === Direction.up) ||
          (this.previousDirection === Direction.up &&
            this.direction === Direction.left)) &&
        rightSide
      ) {
        dominoPiece.reverse();
      }
  
      const position: DominoPosition = {
        domino: dominoPiece,
        key: `${domino[0]}-${domino[1]}`,
        horizontal,
        position: {
          x: this.x - (horizontal ? this.getHalfLength() : this.getHalfWidth()),
          y: this.y - (horizontal ? this.getHalfWidth() : this.getHalfLength()),
        },
        small: this.isSmall,
      };
  
      if (!this.rootPosition) {
        this.rootPosition = position;
      }
  
      this.lastPosition = position;
  
      return position;
    }
  
    determineDirection(): boolean {
      if (this.canPlaceInCurrentDirection()) {
        return false;
      }
  
      this.previousDirection = this.direction;
  
      switch (this.direction) {
        case Direction.up:
          this.direction = Direction.left;
          break;
        case Direction.down:
          this.direction = Direction.right;
          break;
        case Direction.left:
          this.direction = Direction.down;
          break;
        case Direction.right:
          this.direction = Direction.up;
          break;
      }
  
      return true;
    }
  
    canPlaceInCurrentDirection(): boolean {
      let additionalTolerance = 1;
      if (
        this.isSmall &&
        !this.previousDirection &&
        (this.direction === Direction.up || this.direction === Direction.down)
      ) {
        additionalTolerance = 1.2;
      }
  
      if (
        !this.isSmall &&
        !this.previousDirection &&
        (this.direction === Direction.right || this.direction === Direction.left)
      ) {
        additionalTolerance = 1.2;
      }
  
      const length = this.dimensions.length * additionalTolerance;
  
      switch (this.direction) {
        case Direction.up:
          return this.y - length >= this.getHalfLength();
        case Direction.down:
          return this.y + length <= this.height - this.dimensions.width;
        case Direction.left:
          return this.x - length >= this.getHalfWidth();
        case Direction.right:
          return this.x + length <= this.width - this.dimensions.width;
        default:
          return false;
      }
    }
  
    startRightSide(): void {
      this.x = this.initialX;
      this.y = this.initialY;
      this.lastPosition = this.rootPosition;
      this.previousDirection = undefined;
      this.direction =
        this.initialDirection === Direction.left
          ? Direction.right
          : Direction.down;
    }
  }
  