/**
 * Throws an error
 */
export const throwError = (message: string) => {
  throw new Error(message);
};

export interface AssertInput {
  /**
   * If true, an error is thrown.
   * If false, no error will be thrown
   */
  assertion: boolean;

  /**
   * The message used when throwing the error
   */
  message: string;
}

/**
 * Throws an error if the assertion passed
 * is coerced into false
 */
export const assert = (input: AssertInput) => {
  if (input.assertion) {
    throwError(input.message);
  }
};
