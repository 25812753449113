import { GameEvent } from '../shared/game';
import {
  GameEventName,
  GameSide,
  PlayMode,
  SuperDominoMode,
  SuperDominoWinningMethod,
} from './enums';

/**
 * A domino, expressed as a tuple
 */
export type Domino = [number, number];

/**
 * An array of up to 7 dominoes
 */
export type DominoDeck = Domino[];

/**
 * Configuration when starting a new SuperDomino Round
 */
export type SuperDominoGameConfiguration = {
  /**
   * This is added to the prize for the winner.
   * This is set during the initialization of the game.
   */
  extraPrize?: number;
  /**
   * the mode for bonus and points
   */
  mode?: SuperDominoMode;
  /**
   * the play environment mode. Used to
   * determined if playing locally against bots,
   * multiplayer with friends in a private room,
   * or multiplayer with anyone in a public room.
   */
  playMode?: PlayMode;
};

/**
 * Playable value
 */
export type SuperDominoPlayValue = {
  domino: Domino;
  side: GameSide;
};

export type SuperDominoFinishedEventMode = SuperDominoWinningMethod;

/**
 * Different types of events emitted by SuperDomino
 */
export type SuperDominoEvent =
  | GameEvent<GameEventName.started, {}>
  | GameEvent<
      GameEventName.played,
      {
        domino: Domino;
        side: GameSide;
      }
    >
  | GameEvent<
      GameEventName.finished,
      {
        domino: Domino;
        mode: SuperDominoFinishedEventMode;
        winner: number;
      }
    >
  | GameEvent<GameEventName.double_killed, { domino: Domino }>
  | GameEvent<GameEventName.shuffle, {}>
  | GameEvent<GameEventName.pass, { playerWhoPassed: number }>
  | GameEvent<GameEventName.error, { message: string }>;

/**
 * The teams
 */
export enum SuperDominoTeams {
  TeamA,
  TeamB,
}
