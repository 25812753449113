import React, { useContext } from 'react';
import { SuperDominoWinningMethod } from '@littlegoose/common';

import Button from 'src/components/button/button';
import DominoPiece from 'src/components/domino/domino';
import Modal from 'src/components/modal/modal';
import { useUser } from 'src/hooks/use-user';
import { GameContext } from 'src/room/context/game-context';
import { useQuit } from 'src/room/hooks/use-quit';
import gameManager from 'src/services/game-manager';

import './result.css';

const getWinningLabel = (mode: SuperDominoWinningMethod): string => {
  if (mode === SuperDominoWinningMethod.closed) {
    return 'Game was closed!';
  }

  return mode.toUpperCase() + '!';
};

const ResultModal: React.FC<{}> = () => {
  const context = useContext(GameContext);
  const quit = useQuit();
  const [user] = useUser();
  const table = context.table;
  if (table?.gameInProgress()) {
    return null;
  }

  const isHost = table.isHostPlayer(user.id);
  const gameOver = table.isTableGameCompleted();
  const title = gameOver ? 'Game Over' : 'Round Over';
  const myTeamWon = table.didMyTeamWinLastRound(user.id);
  const lastRound = table.getLastRound();
  const winningPlayer = table.getPlayerFromIndex(lastRound.winnerPlayerIdx);

  const onSubmit = async () => {
    if (gameOver) {
      await gameManager.rematch({ context, user });
    } else {
      await gameManager.newRound({ context, user });
    }
  };

  return (
    <Modal
      centerContents={true}
      hideCloseButton
      isOpen={true}
      onClose={() => {}}
      title={title}
    >
      {lastRound.mode !== SuperDominoWinningMethod.normal && (
        <h2 className="playful-font">{getWinningLabel(lastRound.mode)}</h2>
      )}

      <h1 className="playful-font">
        {myTeamWon ? 'Your team won!' : 'Your team lost'}
      </h1>

      {lastRound.mode === SuperDominoWinningMethod.closed ? (
        <div style={{ textAlign: 'center' }}>
          <b>{winningPlayer?.id === user.id ? 'You' : winningPlayer.name}</b>{' '}
          won the round after it was closed with the least number of points!
        </div>
      ) : (
        <div>
          <div style={{ textAlign: 'center' }}>
            <b>{winningPlayer?.id === user.id ? 'You' : winningPlayer.name}</b>{' '}
            won the round after playing:
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px',
            }}
          >
            <DominoPiece
              domino={table.getLastPlayedDomino()}
              horizontal
              small
            />
          </div>
        </div>
      )}

      {isHost && (
        <div className="result-action">
          <Button onClick={() => onSubmit().catch(console.error)}>
            {gameOver ? 'Rematch' : 'Next Round'}
          </Button>
          {gameOver && (
            <>
              <br />
              <Button onClick={() => quit()}>Quit</Button>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ResultModal;
