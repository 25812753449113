import React from 'react';
import { Domino, Player } from '@littlegoose/common';
import './domino.css';
import { Tooltip } from 'react-tooltip';
import { useUser } from 'src/hooks/use-user';

const getDotClassName = (show: boolean) => {
  return show ? 'dot' : 'dot white';
};

const DominoFace: React.FC<{ face: number }> = ({ face }) => {
  const renderMiddleDot = face === 1 || face === 3 || face === 5;
  return (
    <div className="face">
      <div className="row">
        <div className={getDotClassName([4, 5, 6].includes(face))} />
        <div className={getDotClassName([2, 3, 4, 5, 6].includes(face))} />
      </div>
      <div className="row">
        <div className={getDotClassName(face === 6)} />
        {renderMiddleDot && <div className="dot" />}
        <div className={getDotClassName(face === 6)} />
      </div>
      <div className="row">
        <div className={getDotClassName([2, 3, 4, 5, 6].includes(face))} />
        <div className={getDotClassName([4, 5, 6].includes(face))} />
      </div>
    </div>
  );
};

const DominoPiece: React.FC<{
  canPlay?: boolean;
  domino: Domino;
  horizontal?: boolean;
  playedBy?: Player;
  position?: { x: number; y: number };
  onClick?: (domino: Domino) => void;
  small?: boolean;
}> = ({
  canPlay,
  domino,
  horizontal = false,
  playedBy,
  position,
  onClick,
  small = false,
}) => {
  const [user] = useUser();

  if (!domino) {
    return null;
  }
  let topClassName = 'domino';

  if (horizontal) {
    topClassName += ' horizontal';
  }

  if (small) {
    topClassName += ' small';
  }

  if (canPlay) {
    topClassName += ' can-play';
  }

  const id = `${domino[0]}-${domino[1]}`;
  const zIndex = Math.max(0, Math.round(position?.y || 0));

  return (
    <>
      {playedBy && <Tooltip id={id} style={{ zIndex }} />}
      <div
        className={topClassName}
        data-tooltip-id={id}
        data-tooltip-content={
          playedBy
            ? `Played by ${playedBy.id === user.id ? 'Me' : playedBy.name}`
            : ''
        }
        data-tooltip-place="top"
        style={
          position
            ? {
                left: position.x,
                top: position.y,
                zIndex,
              }
            : {}
        }
        id={id}
        onClick={() => {
          if (onClick && canPlay) {
            onClick(domino);
          }
        }}
      >
        <DominoFace face={domino[0]} />
        <div className="line" />
        <DominoFace face={domino[1]} />
      </div>
    </>
  );
};

export default DominoPiece;
