import { useCallback, useState } from 'react';
import { User } from '../types';
import { nanoId } from '../utils/nano-id';

export const useUser = (): [User, (name: string) => void] => {
  const [name, setName] = useState<string>(localStorage.getItem('user_name'));

  if (!localStorage.getItem('user_id')) {
    localStorage.setItem('user_id', nanoId());
  }

  const setUser = useCallback((name: string) => {
    setName(name);
    localStorage.setItem('user_name', name);
  }, []);

  return [
    {
      name,
      id: localStorage.getItem('user_id'),
    },
    setUser,
  ];
};
