import React from 'react';
import MenuIcon from 'src/icons/menu.svg';
import Modal from 'src/components/modal/modal';

import './menu.css';
import { useQuit } from './hooks/use-quit';
import Button from 'src/components/button/button';

const Menu: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const quit = useQuit();
  return (
    <>
      <div className="menu-icon" onClick={() => setIsOpen(true)}>
        <MenuIcon />
      </div>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} title="Game Menu">
        <br />
        <p>Do you want to quit the game?</p>
        <Button onClick={() => quit()}>Quit</Button>
      </Modal>
    </>
  );
};

export default Menu;
