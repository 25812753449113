import { SuperDomino } from '../index';
import { GameSide } from '../enums';
import { Domino } from '../types';

/**
 * Verify if a given Domino is playable on the
 * specified side
 */
export function canPutDomino(
  this: SuperDomino,
  domino: Domino,
  side: GameSide,
) {
  if (!this.root) return true;

  const idx = side === GameSide.left ? 0 : 1;

  if (this[side].length) {
    return domino.indexOf(this[side][this[side].length - 1][idx]) >= 0;
  } else {
    return domino.indexOf(this.root[idx]) >= 0;
  }
}
