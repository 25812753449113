import { SuperDomino } from '../index';
import { assert } from '../../utils/index';
import { GameEventName, GameSide } from '../enums';
import { Domino, SuperDominoPlayValue } from '../types';
import { isDouble, removeDominoFromArray } from '../utils';

/**
 * Method to make a play
 */
export function play(
  this: SuperDomino,
  currentSequence: number,
  playerIndex: number,
  { domino, side }: SuperDominoPlayValue,
): void {
  assert({
    assertion: this.sequence !== currentSequence,
    message: `Invalid sequence. Expected ${this.sequence}, got ${currentSequence}`,
  });

  assert({
    assertion: !this.inProgress(),
    message: `Play cannot be performed if game is not in progress.`,
  });

  assert({
    assertion: playerIndex !== this.currentPlayerIndex,
    message: `Player ${playerIndex} is not the current player. Unable to play!`,
  });

  const hasDomino = this.playerHasDomino(this.currentPlayerIndex, domino);

  assert({
    assertion: !hasDomino,
    message: `Player ${this.currentPlayerIndex} does not have a domino ${domino}`,
  });

  assert({
    assertion: !this.canPutDomino(domino, side),
    message: `Domino ${domino} can't be played on the ${side} side`,
  });

  // remove domino from player's deck
  this.decks[this.currentPlayerIndex] = removeDominoFromArray(
    domino,
    this.getPlayerDeck(this.currentPlayerIndex),
  );

  // remove from unplayed dominoes
  this.unplayed = removeDominoFromArray(domino, this.unplayed);

  const [leftBeforePlay, rightBeforePlay] = this.getSides();

  // play it
  if (!this.root) {
    this.root = domino;
  } else {
    const a = side === GameSide.left ? 0 : 1;
    const b = a === 0 ? 1 : 0;

    if (this[side].length) {
      if (this[side][this[side].length - 1][a] !== domino[b]) {
        domino = domino.reverse() as Domino;
      }
    } else {
      if (this.root[a] !== domino[b]) {
        domino = domino.reverse() as Domino;
      }
    }

    this[side].push(domino);
  }

  this.newEvent({
    event: GameEventName.played,
    payload: {
      domino,
      player: this.currentPlayerIndex,
      side,
    },
  });

  const leftSideDigitRemainingDominoes = this.unplayed.filter(
    (domino) => domino[0] === leftBeforePlay || domino[1] === leftBeforePlay,
  );

  const rightSideDigitRemainingDominoes = this.unplayed.filter(
    (domino) => domino[0] === rightBeforePlay || domino[1] === rightBeforePlay,
  );

  if (
    leftSideDigitRemainingDominoes.length === 1 &&
    isDouble(leftSideDigitRemainingDominoes[0])
  ) {
    this.newEvent({
      event: GameEventName.double_killed,
      payload: {
        domino: leftSideDigitRemainingDominoes[0],
        player: this.currentPlayerIndex,
      },
    });
  }

  if (
    rightSideDigitRemainingDominoes.length === 1 &&
    isDouble(rightSideDigitRemainingDominoes[0])
  ) {
    this.newEvent({
      event: GameEventName.double_killed,
      payload: {
        domino: rightSideDigitRemainingDominoes[0],
        player: this.currentPlayerIndex,
      },
    });
  }

  this.didRoundFinish(domino, leftBeforePlay, rightBeforePlay);

  if (!this.isFinished()) {
    this.setNextPlayer();
  }
}
