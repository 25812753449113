import { SuperDomino } from '../index';

/**
 * Check if a player has at least one domino
 * with the given digit
 */
export function playerHasDigit(
  this: SuperDomino,
  playerIdx: number,
  digit: number,
): boolean {
  return this.getPlayerDeck(playerIdx).some((d) => d.includes(digit));
}
