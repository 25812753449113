import { Domino } from './types';

/**
 * Returns true if the Domino is a Double
 */
export const isDouble = (domino: Domino) => {
  if (!domino) return false;
  return domino[0] === domino[1];
};

/**
 * Generate a set of dominoes
 */
export const generateDominoes = () => {
  const dominoes: Domino[] = [];
  for (let i = 0; i < 7; i++) {
    for (let a = i; a < 7; a++) {
      dominoes.push([i, a]);
    }
  }
  return dominoes;
};

export const areDominoesEqual = (d1: Domino, d2: Domino) => {
  return (
    (d1[0] === d2[0] && d1[1] === d2[1]) || (d1[0] === d2[1] && d1[1] === d2[0])
  );
};

/**
 * Remove a Domino from an array of Dominoes
 * if in the list
 */
export const removeDominoFromArray = (
  domino: Domino,
  dominoes: Domino[],
): Domino[] => {
  return dominoes.filter((d) => !areDominoesEqual(domino, d));
};
