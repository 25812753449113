import { SuperDomino } from '../index';
import { isNullOrUndefined } from '../../utils/index';
import {
  GameEventName,
  SuperDominoMode,
  SuperDominoWinningMethod,
} from '../enums';
import { Domino, SuperDominoEvent } from '../types';

/**
 * Verifies if a round finished. If this method returns an
 * event, then the round finished
 */
export function didRoundFinish(
  this: SuperDomino,
  domino: Domino,
  lastLeft: number,
  lastRight: number,
): void {
  const event: SuperDominoEvent = {
    event: GameEventName.finished,
    payload: {
      domino,
      mode: SuperDominoWinningMethod.normal,
      player: this.currentPlayerIndex,
      winner: this.currentPlayerIndex,
    },
  };

  // check if player has no more pieces
  // if so check how the player won
  if (
    this.getPlayerDeck(this.currentPlayerIndex).length === 0 &&
    !isNullOrUndefined(lastLeft) &&
    !isNullOrUndefined(lastRight)
  ) {
    if (lastLeft === lastRight) {
      event.payload.mode = SuperDominoWinningMethod.dominao;
    } else if (domino[0] === 0 && domino[1] === 0) {
      event.payload.mode = SuperDominoWinningMethod.chuzazo;
    } else if (
      domino.indexOf(lastLeft) >= 0 &&
      domino.indexOf(lastRight) >= 0
    ) {
      event.payload.mode = SuperDominoWinningMethod.kapi;
    }

    // record who the winner was
    this.winnerIndex = this.currentPlayerIndex;

    // finish the game
    this.finish();

    // return the event
    this.newEvent(event);
  }

  // check if game is closed
  const [left, right] = this.getSides();
  if (
    !this.isFinished() &&
    left === right &&
    left !== undefined &&
    left !== null
  ) {
    const gameClosed = !this.areThereAnyDominoesWithDigit(left);

    // if the game is closed, check who has the least points
    if (gameClosed) {
      this.winnerIndex = this.getPlayerWithLeastPointsInDeck();

      // finish the game
      this.finish();

      // return the event
      event.payload.mode = SuperDominoWinningMethod.closed;
      event.payload.winner = this.winnerIndex;
      this.newEvent(event);
    }
  }

  if (this.isFinished()) {
    if (this.config.mode === SuperDominoMode.normal) {
      this.prize = this.unplayed.reduce(
        (total, domino) => total + domino[0] + domino[1],
        0,
      );

      if (
        event.payload.mode === SuperDominoWinningMethod.chuzazo ||
        event.payload.mode === SuperDominoWinningMethod.kapi
      ) {
        this.prize += 100;
      }

      if (this.config.extraPrize) {
        this.prize += this.config.extraPrize;
      }
    } else {
      if (
        event.payload.mode === SuperDominoWinningMethod.chuzazo ||
        event.payload.mode === SuperDominoWinningMethod.kapi
      ) {
        this.prize = 2;
      } else {
        this.prize = 1;
      }
    }
  }
}
