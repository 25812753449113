import { SuperDominoWinningMethod } from '../game/enums';
import { Base } from './base';

export class RoundResult extends Base {
  /**
   * The index of the player that played the last domino
   */
  lastPlayer: number;

  /**
   * The mode of the round
   */
  mode: SuperDominoWinningMethod;

  /**
   * The score of the player that won the round
   */
  score: number;

  /**
   * The sequence number of the round
   */
  sequence: number;

  /**
   * The index of the player that started the round
   */
  starter: number;

  /**
   * Time of event
   */
  timestamp: number;

  /**
   * The player that won the round
   */
  winnerPlayerIdx: number;
}
