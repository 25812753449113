import { useState, useEffect, useRef } from 'react';

/**
 * This hook returns the size of the container element.
 * SuperDomino uses this to determine how to render the dominoes.
 */
export function useContainerSize() {
  const ref = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    });

    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      resizeObserver.unobserve(observeTarget);
    };
  }, []);

  return {
    ref,
    size
  };
}
