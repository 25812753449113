import { v4 as uuidV4 } from 'uuid';
import * as tf from '@tensorflow/tfjs';
import { Base } from './base';
import { Player, PlayerType } from './player';
import { Team } from './team';
import { SuperDomino } from '../game/index';
import {
  Domino,
  DominoDeck,
  SuperDominoGameConfiguration,
  SuperDominoPlayValue,
} from '../game/types';
import { RoundResult } from './round-result';
import { assert, isNullOrUndefined } from '../utils/index';
import { AIBot } from '../bot/index';
import {
  GameEventName,
  GameSide,
  SuperDominoMode,
  TableSeat,
  TeamId,
} from '../game/enums';
import { areDominoesEqual } from '../game/utils';

export class Table extends Base {
  /**
   * Configuration when starting a new SuperDomino Round
   */
  config: Omit<SuperDominoGameConfiguration, 'extraPrize'>;

  /**
   * ID of the table
   */
  id: string = uuidV4();

  /**
   * The players in the table
   */
  playerA: Player | AIBot;
  playerB: Player | AIBot;
  playerC: Player | AIBot;
  playerD: Player | AIBot;

  /**
   * The teams in the table
   */
  teamA: Team;
  teamB: Team;

  /**
   * The game of the table
   */
  currentGame: SuperDomino;

  /**
   * Rounds played
   */
  rounds: RoundResult[] = [];

  constructor(
    config: Omit<SuperDominoGameConfiguration, 'extraPrize'>,
    hostPlayer: Player | AIBot,
  ) {
    super();
    this.config = config;
    this.resetTable();

    this.playerA = hostPlayer;
  }

  /**
   * Set ups the table
   */
  resetTable(): void {
    this.teamA = new Team({ id: TeamId.A, name: 'Team A' });
    this.teamB = new Team({ id: TeamId.B, name: 'Team B' });
    this.rounds = [];
    this.currentGame = null;
  }

  /**
   * Removes a player from the table.
   * The player A cannot be removed.
   * This method should be protected upstream
   * to prevent the host from leaving the table, and to
   * ensure only the host player can remove players.
   */
  removePlayer(playerId: string): void {
    if (this.playerB?.id === playerId) {
      this.playerB = null;
    } else if (this.playerC?.id === playerId) {
      this.playerC = null;
    } else if (this.playerD?.id === playerId) {
      this.playerD = null;
    }
  }

  /**
   * Allows an player to join the table
   */
  join({ player, seat }: { player: Player | AIBot; seat: TableSeat }): void {
    this.removePlayer(player.id);

    if (seat === TableSeat.B) {
      assert({ assertion: !!this.playerB, message: 'Seat B is already taken' });
      this.playerB = player;
    } else if (seat === TableSeat.C) {
      assert({ assertion: !!this.playerC, message: 'Seat C is already taken' });
      this.playerC = player;
    } else if (seat === TableSeat.D) {
      assert({ assertion: !!this.playerD, message: 'Seat D is already taken' });
      this.playerD = player;
    } else {
      throw new Error('Invalid seat');
    }
  }

  /**
   * Returns true if the player is the host
   */
  isHostPlayer(playerId: string): boolean {
    return this.playerA?.id === playerId;
  }

  /**
   * Rturns the current player index
   */
  getCurrentPlayerIndex(): number {
    return this.currentGame.getCurrentPlayerIndex();
  }

  /**
   * Get the current player
   */
  getCurrentPlayer(): Player | AIBot {
    switch (this.getCurrentPlayerIndex()) {
      case 0:
        return this.playerA;
      case 1:
        return this.playerB;
      case 2:
        return this.playerC;
      case 3:
        return this.playerD;
      default:
        throw new Error('Invalid player index');
    }
  }

  /**
   * Get the current team's turn
   */
  getCurrentTeam(): Team {
    const currentPlayerIdx = this.currentGame.getCurrentPlayerIndex();
    return this.getPlayerTeamFromIdx(currentPlayerIdx);
  }

  /**
   * Get the game
   */
  getGame(): SuperDomino {
    return this.currentGame;
  }

  /**
   * Get the last round won
   */
  getLastRound(): RoundResult | null {
    return this.rounds.reduce((acc, round) => {
      if (!acc) {
        return round;
      }

      if (round.sequence > acc.sequence) {
        return round;
      }

      return acc;
    }, null);
  }

  /**
   * Get the index of the player that won the last game
   */
  getLastRoundPlayerWinnerIdx(): number | undefined {
    return this.getLastRound()?.winnerPlayerIdx;
  }

  /**
   * New round
   */
  newRound(): void {
    if (!this.playerA || !this.playerB || !this.playerC || !this.playerD) {
      throw new Error('Not enough players to start a new round');
    }

    if (this.gameInProgress()) {
      throw new Error('Cannot start a new round while a game is in progress');
    }

    const extraPrize = this.getExtraPrize();
    this.currentGame = new SuperDomino();
    this.currentGame.start(
      {
        ...this.config,
        extraPrize,
      },
      this.getLastRoundPlayerWinnerIdx(),
    );
  }

  /**
   * Returns the extra prize for the game,
   * if any.
   */
  getExtraPrize(): number {
    if (this.config.mode !== SuperDominoMode.normal) {
      return 0;
    }

    if (this.rounds.length === 0) {
      return 100;
    } else if (this.rounds.length === 1) {
      return 75;
    } else if (this.rounds.length === 2) {
      return 50;
    } else if (this.rounds.length === 3) {
      return 25;
    } else {
      return 0;
    }
  }

  getTeamScores(playerId: string): { us: number; them: number } {
    const playerIdx = this.getPlayerIndex(playerId);
    if (isNullOrUndefined(playerIdx)) {
      throw new Error('Player not found');
    }

    const us = this.getPlayerTeamFromIdx(playerIdx).score;
    const them = this.getPlayerTeamFromIdx((playerIdx + 1) % 4).score;

    return { us, them };
  }

  /**
   * Get the index of the player
   */
  getPlayerIndex(playerId: string): number | null {
    if (this.playerA?.id === playerId) {
      return 0;
    } else if (this.playerB?.id === playerId) {
      return 1;
    } else if (this.playerC?.id === playerId) {
      return 2;
    } else if (this.playerD?.id === playerId) {
      return 3;
    }

    return null;
  }

  getPlayerFromIndex(idx: number): Player | null {
    switch (idx) {
      case 0:
        return this.playerA;
      case 1:
        return this.playerB;
      case 2:
        return this.playerC;
      case 3:
        return this.playerD;
      default:
        return null;
    }
  }

  /**
   * Given a player index, it returns the team
   */
  getPlayerTeamFromIdx(playerIdx: number): Team {
    if (playerIdx === 0 || playerIdx === 2) {
      return this.teamA;
    } else if (playerIdx === 1 || playerIdx === 3) {
      return this.teamB;
    } else {
      throw new Error('Invalid player index');
    }
  }

  /**
   * Method for playing.
   * The playerId must be the current player.
   * The currentSequence must match the current sequence of the game.
   * The domino must be in the player's deck.
   */
  play(
    playerId: string,
    currentSequence: number,
    domino: SuperDominoPlayValue,
  ): void {
    if (this.currentGame.isFinished()) {
      return;
    }

    const playerIdx = this.getPlayerIndex(playerId);
    this.currentGame.play(currentSequence, playerIdx, domino);

    if (this.currentGame.isFinished()) {
      const finishedEvent = this.currentGame.events.find(
        (e) => e.event === GameEventName.finished,
      );
      this.rounds.push(
        RoundResult.create({
          lastPlayer: this.currentGame.currentPlayerIndex,
          mode: finishedEvent.payload['mode'],
          score: this.currentGame.getPrize(),
          starter: this.currentGame.initialPlayerIndex,
          sequence: this.rounds.length + 1,
          timestamp: Date.now(),
          winnerPlayerIdx: this.currentGame.getWinner(),
        }),
      );

      const team = this.getPlayerTeamFromIdx(this.currentGame.getWinner());
      team.score = team.score ?? 0;
      team.addScore(this.currentGame.getPrize());
    }
  }

  /**
   * Returns the current sequence of the game
   */
  getSequence(): number {
    return this.getGame().sequence;
  }

  /**
   * Returns true if the game is still in progress
   */
  gameInProgress(): boolean {
    if (!this.getGame()) {
      return false;
    }

    return this.getGame().inProgress();
  }

  /**
   * Returns true if the game round finished
   */
  lastGameFinished(): boolean {
    return this.getGame().isFinished();
  }

  /**
   *  Returns the deck of dominoes of the player
   */
  getPlayerDeck(playerId: string): DominoDeck {
    const playerIdx = this.getPlayerIndex(playerId);
    if (isNullOrUndefined(playerIdx)) {
      throw new Error('Player not found');
    }
    return this.getGame().getPlayerDeck(playerIdx);
  }

  /**
   *  Returns the deck of dominoes of the player
   */
  getPlayerDeckByIndex(playerIdx: number): DominoDeck {
    return this.getGame().getPlayerDeck(playerIdx);
  }

  getCurrentPlayerDeck(): DominoDeck {
    return this.getPlayerDeck(this.getCurrentPlayer().id);
  }

  getCurrentPlayerPlayableDominoes(): Domino[] {
    const game = this.getGame();
    return this.getCurrentPlayerDeck().filter(
      (domino) =>
        game.canPutDomino(domino, GameSide.left) ||
        game.canPutDomino(domino, GameSide.right),
    );
  }

  canPutDomino(domino: Domino, side: GameSide): boolean {
    return this.getGame().canPutDomino(domino, side);
  }

  /**
   * Returns true if the table overall game is
   * completed, and a team has won.
   */
  isTableGameCompleted(): boolean {
    if (this.config.mode === SuperDominoMode.normal) {
      return this.teamA.score >= 500 || this.teamB.score >= 500;
    }

    if (this.config.mode === SuperDominoMode.super) {
      return this.teamA.score >= 5 || this.teamB.score >= 5;
    }

    return false;
  }

  /**
   * Returns the winning team of the table game
   */
  getTableWinner(): Team | null {
    if (this.config.mode === SuperDominoMode.super) {
      if (this.teamA.score >= 5) {
        return this.teamA;
      }

      if (this.teamB.score >= 5) {
        return this.teamB;
      }
    }

    if (this.config.mode === SuperDominoMode.normal) {
      if (this.teamA.score >= 500) {
        return this.teamA;
      }

      if (this.teamB.score >= 500) {
        return this.teamB;
      }
    }

    return null;
  }

  isPlayersTurn(playerId: string): boolean {
    return this.getCurrentPlayer().id === playerId;
  }

  getOtherPlayersStats(playerId: string): {
    left: {
      dominoCount: number;
      player: Player;
    };
    front: {
      dominoCount: number;
      player: Player;
    };
    right: {
      dominoCount: number;
      player: Player;
    };
  } {
    const playerIdx = this.getPlayerIndex(playerId);
    if (isNullOrUndefined(playerIdx)) {
      throw new Error('Player not found');
    }

    const leftIdx = (playerIdx + 3) % 4;
    const frontIdx = (playerIdx + 2) % 4;
    const rightIdx = (playerIdx + 1) % 4;

    return {
      left: {
        dominoCount: this.getPlayerDeckByIndex(leftIdx).length,
        player: this.getPlayerFromIndex(leftIdx),
      },
      front: {
        dominoCount: this.getPlayerDeckByIndex(frontIdx).length,
        player: this.getPlayerFromIndex(frontIdx),
      },
      right: {
        dominoCount: this.getPlayerDeckByIndex(rightIdx).length,
        player: this.getPlayerFromIndex(rightIdx),
      },
    };
  }

  getTheHandPlayer(): Player {
    const game = this.getGame();
    const playerIdx = game.getTheHand();
    return this.getPlayerFromIndex(playerIdx);
  }

  isTheHand(playerId: string): boolean {
    return this.getTheHandPlayer().id === playerId;
  }

  didPlayerPass(playerId: string): boolean {
    const playerIdx = this.getPlayerIndex(playerId);
    const event = this.getGame()
      .events.filter(
        (e) =>
          (e.event === GameEventName.pass &&
            e.payload.playerWhoPassed === playerIdx) ||
          (e.event === GameEventName.played && e.payload.player === playerIdx),
      )
      .reduce((acc, e) => {
        if (!acc) {
          return e;
        }

        if (e.id > acc.id) {
          return e;
        }

        return acc;
      }, null);

    if (!event) {
      return false;
    }

    return event.event === GameEventName.pass;
  }

  whoPlayedDomino(domino: Domino): Player | undefined {
    const playedEvent = this.getGame().events.find(
      (e) =>
        e.event === GameEventName.played &&
        areDominoesEqual(e.payload.domino, domino),
    );
    if (!playedEvent) {
      return undefined;
    }

    return this.getPlayerFromIndex(playedEvent.payload.player);
  }

  didMyTeamWinLastRound(playerId: string): boolean {
    const playerIdx = this.getPlayerIndex(playerId);
    const lastRound = this.getLastRound();
    if (!lastRound) {
      return false;
    }

    return (
      this.getPlayerTeamFromIdx(playerIdx).id ===
      this.getPlayerTeamFromIdx(lastRound.winnerPlayerIdx).id
    );
  }

  getLastPlayedDomino(): Domino | undefined {
    const finishedEvent = this.getGame().events.find(
      (e) => e.event === GameEventName.finished,
    );
    if (!finishedEvent) {
      return undefined;
    }

    return (
      finishedEvent.event === GameEventName.finished &&
      finishedEvent.payload.domino
    );
  }

  static fromJSON(json: Partial<Table>, model?: tf.Sequential): Table {
    const playerA =
      json.playerA.type === PlayerType.Human
        ? new Player(json.playerA)
        : new AIBot(model);
    Object.assign(playerA, json.playerA);

    const table = new Table(json.config, playerA);
    Object.assign(table, json);
    table.playerA = playerA;

    const players = ['playerB', 'playerC', 'playerD'];
    for (const player of players) {
      if (!json[player]) {
        continue;
      }

      const playerObj =
        json[player].type === PlayerType.Human
          ? new Player(json[player])
          : new AIBot(model);
      Object.assign(playerObj, json[player]);
      if (playerObj.type === PlayerType.Bot) {
        (playerObj as AIBot).model = model;
      }
      table[player] = playerObj;
    }

    table.teamA = new Team(json.teamA);
    Object.assign(table.teamA, json.teamA);

    table.teamB = new Team(json.teamB);
    Object.assign(table.teamB, json.teamB);

    if (json.currentGame) {
      table.currentGame = SuperDomino.fromData(json.currentGame);
    }

    table.rounds = json.rounds.map((r) => RoundResult.fromData(r));

    return table;
  }
}
