import { useEffect, useState } from "react"
import * as tf from '@tensorflow/tfjs'

export const MODEL_URL =
    window.location.protocol + '//' + window.location.host + '/models/rc1/model.json'

export const useBotModel = () => {
    const [model, setModel] = useState<tf.Sequential | null>(null)

    useEffect(() => {
        ;(async () => {
            const model = await tf.loadLayersModel(MODEL_URL) as tf.Sequential;
            setModel(model)
        })()
    }, [])

    return model
}