import { Game } from '../shared/game';
import {
  Domino,
  DominoDeck,
  SuperDominoEvent,
  SuperDominoGameConfiguration,
} from './types';

import { anyPlayerHasFiveOrMoreDoubles } from './methods/any-player-has-five-or-more-doubles';
import { areThereAnyDominoesWithDigit } from './methods/are-there-any-dominoes-with-digit';
import { canPutDomino } from './methods/can-put-domino';
import { didRoundFinish } from './methods/did-round-finish';
import { getNextPlayerIndex } from './methods/get-next-player-index';
import { getPlayerPointsInDeck } from './methods/get-player-points-in-deck';
import { getPlayerTeam } from './methods/get-player-team';
import { getPlayerWithLeastPointsInDeck } from './methods/get-player-with-least-points-in-deck';
import { getSideDigit } from './methods/get-side-digit';
import { getSides } from './methods/get-sides';
import { play } from './methods/play';
import { playerHasDomino } from './methods/player-has-domino';
import { playerWithDomino } from './methods/player-with-domino';
import { shuffleDominoes } from './methods/shuffle-dominoes';
import { start } from './methods/start';
import { getPlayerDeck } from './methods/get-player-deck';
import { playerHasDigit } from './methods/player-has-digit';
import { setNextPlayer } from './methods/set-next-player';
import { getTheHand } from './methods/get-the-hand';
import { getSideDomino } from './methods/get-side-domino';
import { getSideDominoes } from './methods/get-side-dominoes';

export class SuperDomino extends Game<SuperDominoEvent> {
  /**
   * Configuration when starting a new SuperDomino Round
   */
  config: SuperDominoGameConfiguration;

  /**
   * This represents which dominoes each player
   * currently has. The index of the array indicates the
   * player
   */
  decks: DominoDeck[];

  /**
   * List of dominoes still not played
   */
  unplayed: Domino[] = [];

  /**
   * The first domino played
   */
  root?: Domino;

  /**
   * Dominoes played on the left side
   */
  left: Domino[] = [];

  /**
   * Dominoes played on the right side
   */
  right: Domino[] = [];

  /**
   * The index of the initial player
   */
  initialPlayerIndex: number = 0;

  /**
   * ---- Methods -----
   *
   * Checks if any of the players have 5 or more
   * doubles in their decks
   */
  anyPlayerHasFiveOrMoreDoubles = anyPlayerHasFiveOrMoreDoubles;

  /**
   * Returns true if there are still dominoes of this digit unplayed
   */
  areThereAnyDominoesWithDigit = areThereAnyDominoesWithDigit;

  /**
   * Verify if a given Domino is playable on the
   * specified side
   */
  canPutDomino = canPutDomino;

  /**
   * Verifies if a round finished. If this method returns an
   * event, then the round finished
   */
  didRoundFinish = didRoundFinish;

  /**
   * Given a player index, this method will
   * return the index of the next player
   */
  getNextPlayerIndex = getNextPlayerIndex;

  /**
   * Given a player index, return the
   * domino deck
   */
  getPlayerDeck = getPlayerDeck;

  /**
   * Returns the sum of all the Domino numbers for a given player
   */
  getPlayerPointsInDeck = getPlayerPointsInDeck;

  /**
   * Returns the team on which the player belongs to
   */
  getPlayerTeam = getPlayerTeam;

  /**
   * Calculates the sum of dominoes for each player. This method will
   * return the index of the player that has the least amount of points.
   */
  getPlayerWithLeastPointsInDeck = getPlayerWithLeastPointsInDeck;

  /**
   * Get the digit playable on the specified side
   */
  getSideDigit = getSideDigit;

  /**
   * Returns a tuple representing the digits on each side
   * of the Domino Table
   */
  getSides = getSides;

  /**
   * Returns the domino on the specified side
   */
  getSideDomino = getSideDomino;

  /**
   * Returns a tuple representing the dominoes on each side
   */
  getSideDominoes = getSideDominoes;

  /**
   * Get the player who is the "hand"
   */
  getTheHand = getTheHand;

  /**
   * Method to make a play
   */
  play = play;

  /**
   * Check if a player has at least one domino
   * with the given digit
   */
  playerHasDigit = playerHasDigit;

  /**
   * Check if a player has a given domino
   */
  playerHasDomino = playerHasDomino;

  /**
   * Get the player that is holding the specified
   * domino
   */
  playerWithDomino = playerWithDomino;

  /**
   * Search for the next player, and
   * set it as the current player
   */
  setNextPlayer = setNextPlayer;

  /**
   * Generate the domino pieces to use for the game,
   * and distribute them to the players
   */
  shuffleDominoes = shuffleDominoes;

  /**
   * Start a new round of Domino
   */
  start = start;
}
