import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import useVH from 'react-viewport-height';

import Room from './room/room';
import ErrorPage from 'src/error';
import MainMenu from 'src/main-menu/main-menu';

import './app.css';

const router = createBrowserRouter([
  {
    path: '/play/:tableId',
    element: <Room />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <MainMenu />,
    errorElement: <ErrorPage />,
  },
]);

const App: React.FC<{}> = () => {
  useVH();
  return (
    <div className="app-container">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
