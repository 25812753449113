import { SuperDomino } from '../index';
import { GameSide } from '../enums';

/**
 * Returns a tuple representing the digits on each side
 * of the Domino Table
 */
export function getSides(this: SuperDomino): [number, number] {
  return [this.getSideDigit(GameSide.left), this.getSideDigit(GameSide.right)];
}
