import React from 'react';
import './button.css';

const Button: React.FC<{
    onClick: () => void;
} & React.PropsWithChildren> = ({ children, onClick }) => {
    return (
        <button className='sp-button playful-font' onClick={onClick}>
            {children}
        </button>
    )
}

export default Button;