import React, { useEffect, useState } from 'react';
import { IFGameContext } from './types';
import { PlayMode, Table } from '@littlegoose/common';
import { useBotModel } from 'src/hooks/use-bot-model';
import { useUser } from 'src/hooks/use-user';
export * from './types';

const defaultContext: IFGameContext = {
  playerId: null,
  sequence: null,
  table: null,
  updateContext: () => {},
};

export const GameContext = React.createContext<IFGameContext>(defaultContext);

export const useGameContext = (tableId?: string) => {
  const model = useBotModel();
  const [user] = useUser();

  const [state, setState] = useState<IFGameContext>(undefined);

  useEffect(() => {
    if (!state && model && tableId) {
      const DEFAULT = Object.assign({}, defaultContext);

      if (tableId === 'local' && localStorage.getItem('local')) {
        const table = Table.fromJSON(
          JSON.parse(localStorage.getItem('local')),
          model as any,
        );
        DEFAULT.table = table;
        DEFAULT.sequence = table.getSequence();
        DEFAULT.playerId = user.id;
      }

      setState(
        Object.assign({}, DEFAULT, {
          updateContext: (updatedState: Partial<IFGameContext>) => {
            setState((prevState) => {
              const updatedContext = Object.assign({}, prevState, updatedState);
              if (updatedContext?.table?.config?.playMode === PlayMode.local) {
                localStorage.setItem(
                  'local',
                  JSON.stringify(updatedContext.table),
                );
              }
              return updatedContext;
            });
          },
        }),
      );
    }
  }, [state, model, tableId, user]);

  return state;
};
