import { SuperDomino } from '../index';
import { Domino } from '../types';

/**
 * Get the player that is holding the specified
 * domino
 */
export function playerWithDomino(
  this: SuperDomino,
  domino: Domino,
): number | undefined {
  for (let i = 0; i < 4; i++) {
    if (this.playerHasDomino(i, domino)) {
      return i;
    }
  }
  return undefined;
}
