import { SuperDomino } from '../index';
import { GameEventName } from '../enums';

/**
 * Search for the next player, and
 * set it as the current player
 */
export function setNextPlayer(this: SuperDomino): void {
  const [left, right] = this.getSides();
  const player = this.currentPlayerIndex;
  this.currentPlayerIndex = this.getNextPlayerIndex();
  let attempts = 0;
  while (attempts < 4) {
    if (
      this.playerHasDigit(this.currentPlayerIndex, left) ||
      this.playerHasDigit(this.currentPlayerIndex, right)
    ) {
      return;
    } else {
      this.newEvent({
        event: GameEventName.pass,
        payload: {
          player,
          playerWhoPassed: this.currentPlayerIndex,
        },
      });
      this.currentPlayerIndex = this.getNextPlayerIndex();
    }
    attempts++;
  }

  this.newEvent({
    event: GameEventName.error,
    payload: {
      message: 'No player can play',
    },
  });
}
