import { SuperDomino } from '../index';

/**
 * Returns true if there are still dominoes of this digit unplayed
 */
export function areThereAnyDominoesWithDigit(
  this: SuperDomino,
  digit: number,
): boolean {
  return !!this.unplayed.find((d) => d[0] === digit || d[1] === digit);
}
