import React, { useContext, useState } from 'react';
import { Domino, GameSide, areDominoesEqual } from '@littlegoose/common';

import gameManager from 'src/services/game-manager';
import DominoPiece from 'src/components/domino/domino';
import { GameContext } from 'src/room/context/game-context';
import PlaySelection from './play-selection';

import './deck.css';

const Deck: React.FC<{}> = () => {
  const [selectedDomino, setSelectedDomino] = useState<Domino | null>(null);
  const context = useContext(GameContext);
  const { playerId, table } = context;

  const dominoes = table.getPlayerDeck(playerId);
  const myTurn = table.isPlayersTurn(playerId);
  const [leftSide, rightSide] = table.getGame().getSideDominoes();

  const playableDominoes = myTurn
    ? table.getCurrentPlayerPlayableDominoes()
    : [];

  const play = async (domino: Domino, side: GameSide) => {
    await gameManager.playHumanTurn({ context, domino, side });
  };

  return (
    <div className="controls">
      <PlaySelection
        domino={selectedDomino}
        leftSide={leftSide}
        rightSide={rightSide}
        onClose={() => setSelectedDomino(null)}
        onSelection={(domino, side) => {
          setSelectedDomino(null);
          play(domino, side);
        }}
      />
      <div className="deck">
        <div className="deck-status">
          {table.isTheHand(playerId) && <div className="hand">👋</div>}
          {myTurn && (
            <div className="current-turn boogaloo-regular">My Turn</div>
          )}
          {table.didPlayerPass(playerId) && !myTurn && (
            <div className="passed boogaloo-regular">Passed</div>
          )}
        </div>
        <div className="deck-set">
          {dominoes.map((domino, idx) => (
            <DominoPiece
              canPlay={
                playableDominoes.some((d) => areDominoesEqual(d, domino)) &&
                myTurn
              }
              domino={domino}
              key={`my-deck-${idx}`}
              onClick={() => {
                if (!myTurn) return;
                if (!leftSide && !rightSide) {
                  play(domino, GameSide.left);
                  return
                }

                const canPutOnLeft = table.canPutDomino(domino, GameSide.left);
                if (
                  canPutOnLeft &&
                  table.canPutDomino(domino, GameSide.right)
                ) {
                  setSelectedDomino(domino);
                } else {
                  play(domino, canPutOnLeft ? GameSide.left : GameSide.right);
                }
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Deck;
