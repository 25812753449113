import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useQuit = () => {
    const navigate = useNavigate();

    const quit = useCallback(() => {
        localStorage.removeItem('local');
        navigate('/');
    }, [navigate])

    return quit
}