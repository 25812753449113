import { SuperDomino } from '../index';

/**
 * Get the player who is the "hand"
 */
export function getTheHand(this: SuperDomino): number {
  if (this.winnerIndex) {
    return this.winnerIndex;
  }

  let theHand = this.currentPlayerIndex;
  let nextPlayer = this.getNextPlayerIndex(theHand);

  while (nextPlayer !== this.currentPlayerIndex) {
    if (
      this.getPlayerDeck(nextPlayer).length < this.getPlayerDeck(theHand).length
    ) {
      theHand = nextPlayer;
    }
    nextPlayer = this.getNextPlayerIndex(nextPlayer);
  }

  return theHand;
}
