import { shuffle } from 'lodash';
import { SuperDomino } from '../index';
import { generateDominoes } from '../utils';
import { GameEventName } from '../enums';
import { Domino } from '../types';

function sortAndTransform(arr: Domino[]): Domino[] {
  // Step 1: Normalize each tuple
  const normalized: Domino[] = arr.map(([a, b]) => (a > b ? [b, a] : [a, b]));

  // Step 2: Sort tuples primarily by the second element, secondarily by the first element
  normalized.sort((a, b) => {
    if (a[1] === b[1]) {
      // If the second elements are equal, sort by the first
      return a[0] - b[0];
    }
    return a[1] - b[1]; // Otherwise, sort by the second element
  });

  return normalized;
}

/**
 * Generate the domino pieces to use for the game,
 * and distribute them to the players
 */
export function shuffleDominoes(this: SuperDomino): void {
  // generate the dominoes already shuffled
  const dominoes = shuffle(generateDominoes());

  this.unplayed = [...dominoes];
  this.decks = [[], [], [], []];

  let playerIdx = 0;

  // distribute the dominoes
  for (const domino of dominoes) {
    this.getPlayerDeck(playerIdx).push(domino);
    playerIdx = this.getNextPlayerIndex(playerIdx);
  }

  // redo the shuffle if any player has
  // five or more doubles in their deck
  if (this.anyPlayerHasFiveOrMoreDoubles()) {
    return this.shuffleDominoes();
  }

  // sort the dominoes in the decks
  this.decks = this.decks.map(sortAndTransform);

  // create and return a "shuffle" event
  this.newEvent({ event: GameEventName.shuffle });
}
